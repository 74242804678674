main {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-start;
    align-items: center;
    overflow-y: auto;
    margin-top: 1rem;
  }

  ul {
    width: 100% ;
    list-style: none;
    padding: 0 0.25rem 0.25rem;
  }
  
  ul li::before {
    content: "\200B";
  }
  
  .item {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0.5rem 0 0.5rem 0.5rem;
    margin: 0.25rem 0;
    background-color: #eee;
  }
  
  .item:first-child {
    margin: 0;
  }
  
  .item input[type="checkbox"] {
    text-align: center;
    width: 2.5rem;
    width: 48px;
    min-width: 48px;
    height: 2.5rem;
    height: 48px;
    min-height: 48px;
    cursor: pointer;
    margin-right: 0.5rem;
  }
  
  .item input[type="checkbox"]:focus + label {
    text-decoration: underline;
  }
  
  .item > label {
    cursor: pointer;
    font-size: 0.75rem;
    flex-grow: 1;
  }
  
  .item svg {
    width: 48px;
    min-width: 48px;
    height: 36px;
    font-size: 1rem;
    color: steelblue;
    cursor: pointer;
  }
  
  .item svg:focus, 
  .item svg:hover {
    color: red;
    outline: none;
  }