.searchForm {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin: 0.25rem 0 1rem;
    padding: 0 0.5rem 0.25rem;
    border-bottom: 1px solid #eee;
  }
  
  .searchForm label {
    position: absolute;
    left: -99999px;
  }
  
  .searchForm input[type='text'] {
    flex-grow: 1;
    max-width: 100%;
    min-height: 48px;
    font-size: 1rem;
    padding: 0.25rem;
    border-radius: 0.25rem;
    outline: none;
  }